<template>
  <div class="MobileHome bg-blue column">
    <!-- MOBILE MENU LOGO -->
    <img
      alt="Bobtail's logo"
      class="MobileHome__logo"
      src="../assets/logo--white.png"
    >

    <!-- Smoothly transition between loading indicator and card view -->
    <transition
      mode="out-in"
      name="Transition__opacity-fade"
    >
      <!-- Loading Indicator -->
      <div
        v-if="!createdFinished"
        :key="'loading-indicator'"
      >
        <div class="MobileHome__loading-indicator">
          <i class="fa fa-processing fa-spin fa--none fc-white fs-60" />
        </div>
      </div>
      <!-- Submit Button + User (client) Cards -->
      <div
        v-else
        class="width-100"
        :key="'submit-btn-user-cards'"
      >
        <!-- Submit Button (not visible if client needs a bank/active bank needs verified) -->
        <base-button
          v-if="createdFinished && (!clientWithoutBankModal || !clientWithActiveBankReqVerification)"
          @click="goToSubmitInvoice"
          class="bg-green fc-white fw-med mt-21 width-100"
          id="Testing__submitinvoice"
        >
          <i class="fa fa-add-circle" />
          Submit Invoice
        </base-button>

        <!-- Cards -->
        <transition-group
          class="column"
          name="Transition__fade"
          tag="div"
        >
          <div
            v-if="user.client"
            class="column"
            :key="'client-view'"
          >
            <!-- Banking (not active || verification required active bank) -->
            <div
              v-if="clientWithoutBankModal || clientWithActiveBankReqVerification"
              @click="goToBanking"
              class="MobileHome__card"
              id="Testing__bankaccountinfo"
            >
              <div
                class="MobileHome__card-header"
                tabindex="0"
              >
                <div class="row row--align-center row--width-auto">
                  <label class="MobileHome__card-header-lbl">Bank Account</label>
                  <label class="fc-red fs-14 ml-6">(Required)</label>
                </div>
                <i class="fa fa-chevron fc-blue" />
              </div>

              <hr class="Divider">

              <template v-if="apiResults.banks.length">
                <base-bank
                  v-for="bank in apiResults.banks"
                  :key="bank.id"
                  :bank-account="bank"
                  :user-is-client="!!user.client"
                />
              </template>

              <template v-else>
                <label class="row mt-14">No banks added</label>
              </template>
            </div>

            <!-- Transfers -->
            <div
              @click="goToTransfers"
              class="MobileHome__card"
            >
              <div
                class="MobileHome__card-header"
                tabindex="0"
              >
                <label class="MobileHome__card-header-lbl">Transfers</label>
                <i class="fa fa-chevron fc-blue" />
              </div>

              <hr class="Divider">

              <template v-if="apiResults.transfers.length">
                <div
                  v-for="(result, index) in apiResults.transfers"
                  :key="`transfers-${index}`"
                  class="MobileHome__result column"
                >
                  <div class="row row--align-center row--justify-between">
                    <label class="fs-16 fw-med">{{ result.created_at | datetime('MM/DD/YYYY') }}</label>
                    <label class="fs-16 fw-med">
                      {{
                        Intl
                          .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                          .format(result.amount / 100)
                      }}
                    </label>
                  </div>
                </div>
              </template>

              <template v-else>
                <label class="row">No transfers currently</label>
              </template>
            </div>

            <!-- Pending Invoices -->
            <div
              @click="goToPendingInvoices"
              class="MobileHome__card"
              id="Testing__pendinginvoices"
            >
              <div
                class="MobileHome__card-header"
                tabindex="0"
              >
                <label class="MobileHome__card-header-lbl">Pending Invoices</label>
                <i class="fa fa-chevron fc-blue" />
              </div>

              <hr class="Divider">

              <template v-if="apiResults.pending.length">
                <div
                  v-for="(result, index) in apiResults.pending"
                  :key="`pending-${index}`"
                  class="MobileHome__result column"
                >
                  <div class="row row--align-center row--justify-between">
                    <label class="fs-16 fw-med">
                      {{ sanitizeAlgoliaNames(result.debtor_selected_name) }}
                    </label>
                    <label class="fs-16 fw-med">
                      {{
                        Intl
                          .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                          .format(result.total_amount / 100)
                      }}
                    </label>
                  </div>
                  <div class="row row--justify-between">
                    <label class="fc-mid fs-14">{{ result.created_at | datetime('MM/DD/YYYY') }}</label>
                    <label class="fc-red fs-14 uppercase">{{ formatFlagMessage(result.last_flag_message) }}</label>
                  </div>
                </div>
              </template>

              <template v-else>
                <label class="row mt-14">No pending invoices</label>
              </template>
            </div>

            <!-- Approved Invoices -->
            <div
              @click="goToApprovedInvoices"
              class="MobileHome__card"
              id="Testing__approvedinvoices"
            >
              <div
                class="MobileHome__card-header"
                tabindex="0"
              >
                <label class="MobileHome__card-header-lbl">Approved Invoices</label>
                <i class="fa fa-chevron fc-blue" />
              </div>

              <hr class="Divider">

              <template v-if="apiResults.approved.length">
                <div
                  v-for="(result, index) in apiResults.approved"
                  :key="`approved-${index}`"
                  class="MobileHome__result column"
                >
                  <div class="row row--align-center row--justify-between">
                    <label class="fs-16 fw-med">
                      {{ sanitizeAlgoliaNames(result.debtor_selected_name) }}
                    </label>
                    <label class="fs-16 fw-med">
                      {{
                        Intl
                          .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                          .format(result.total_amount / 100)
                      }}
                    </label>
                  </div>
                  <div class="row row--justify-between">
                    <label class="fc-mid fs-14">{{ result.created_at | datetime('MM/DD/YYYY') }}</label>
                    <label class="fc-red fs-14 uppercase">{{ formatFlagMessage(result.last_flag_message) }}</label>
                  </div>
                </div>
              </template>

              <template v-else>
                <label class="row">No approved invoices</label>
              </template>
            </div>

            <!-- Shortpaid Invoices -->
            <div
              @click="goToShortpaidInvoices"
              class="MobileHome__card"
              id="Testing__shortpaidinvoices"
            >
              <div
                class="MobileHome__card-header"
                tabindex="0"
              >
                <label class="MobileHome__card-header-lbl">Shortpaid Invoices</label>
                <i class="fa fa-chevron fc-blue" />
              </div>

              <hr class="Divider">

              <template v-if="apiResults.shortpaid.length">
                <div
                  v-for="(result, index) in apiResults.shortpaid"
                  :key="`shortpaid-${index}`"
                  class="MobileHome__result column"
                >
                  <div class="row row--align-center row--justify-between">
                    <label class="fs-16 fw-med">
                      {{ sanitizeAlgoliaNames(result.debtor_selected_name) }}
                    </label>
                    <label class="fs-16 fw-med">
                      {{
                        Intl
                          .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                          .format(result.total_amount / 100)
                      }}
                    </label>
                  </div>
                  <div class="row row--justify-between">
                    <label class="fc-mid fs-14">{{ result.created_at | datetime('MM/DD/YYYY') }}</label>
                    <label class="fc-red fs-14 uppercase">{{ formatFlagMessage(result.last_flag_message) }}</label>
                  </div>
                </div>
              </template>

              <template v-else>
                <label class="row mt-14">No shortpaid invoices</label>
              </template>
            </div>

            <!-- Balance History -->
            <div
              @click="goToBalanceHistory"
              class="MobileHome__card"
              id="Testing__balancehistory"
            >
              <div
                class="MobileHome__card-header"
                tabindex="0"
              >
                <div class="row row--align-center row--width-auto">
                  <label class="MobileHome__card-header-lbl">Balance History</label>
                  <label
                    :class="['MobileHome__balance-flag fs-16 ml-8', {
                      'MobileHome__balance-flag--bg-light': apiResults.clientBalance === 0,
                      'MobileHome__balance-flag--bg-green': apiResults.clientBalance > 0,
                      'MobileHome__balance-flag--bg-red': apiResults.clientBalance < 0,
                    }]"
                  >
                    {{
                      Intl
                        .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                        .format(apiResults.clientBalance / 100)
                    }}
                  </label>
                </div>
                <i class="fa fa-chevron fc-blue" />
              </div>

              <hr class="Divider">

              <template v-if="apiResults.balances.length">
                <div
                  v-for="(result, index) in apiResults.balances"
                  :key="`balances-${index}`"
                  class="MobileHome__result column"
                >
                  <div class="row row--align-center row--justify-between">
                    <div class="column column--width-auto">
                      <label class="fs-16 fw-med capitalize">{{ debtorPaymentText(result.reason) }}</label>
                      <label class="fc-mid fs-14">{{ result.created_at | datetime('MM/DD/YYYY') }}</label>
                    </div>
                    <label
                      :class="['fs-16 fw-med', {
                        'fc-red': result.nacha_sign === '-'
                      }]"
                    >
                      <span
                        v-if="result.nacha_sign === '-'"
                        class="MobileHome__nacha_sign fc-red"
                      >
                        -
                      </span>
                      {{
                        Intl
                          .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                          .format(result.amount / 100)
                      }}
                    </label>
                  </div>
                </div>
              </template>

              <template v-else>
                <label class="row mt-14">No balances</label>
              </template>
            </div>

            <!-- Credit Check -->
            <div class="MobileHome__card MobileHome__card--noclickable">
              <div class="MobileHome__card-header">
                <label class="MobileHome__card-header-lbl">Credit Check</label>
              </div>

              <hr class="Divider">

              <base-debtor-search
                @selected="selectDebtor"
                class="MobileHome__debtor-search width-100"
                :label="true"
                :label-for="'debtor'"
                :placeholder="'Company name or MC ID'"
                :statuses="['active', 'sandbox']"
              />

              <transition name="Transition__fade">
                <base-credit-check-item
                  v-if="debtor"
                  class="mt-14"
                  :debtor="debtor"
                />
              </transition>
            </div>

            <!-- Banking (when banking at the top is not displayed ) -->
            <div
              v-if="!clientWithoutBankModal && !clientWithActiveBankReqVerification"
              @click="goToBanking"
              class="MobileHome__card"
              id="Testing__bankaccountinfo"
            >
              <div
                class="MobileHome__card-header"
                tabindex="0"
              >
                <div class="row row--align-center row--width-auto">
                  <label class="MobileHome__card-header-lbl">Bank Account</label>
                  <label class="fc-red fs-14 ml-6">(Required)</label>
                </div>
                <i class="fa fa-chevron fc-blue" />
              </div>

              <hr class="Divider">

              <template v-if="apiResults.banks.length">
                <base-bank
                  v-for="bank in apiResults.banks"
                  :key="bank.id"
                  :bank-account="bank"
                  :user-is-client="!!user.client"
                />
              </template>

              <template v-else>
                <label class="row mt-14">No banks added</label>
              </template>
            </div>

            <!-- Account Manager -->
            <!-- The desktop version is in navigation-desktop.vue -->
            <div class="MobileHome__card MobileHome__card--noclickable">
              <div class="MobileHome__card-header">
                <label class="fc-dark fs-18 fw-med">Your Account Rep</label>
              </div>

              <hr class="Divider">

              <template v-if="apiResults.accountManager">
                <div class="row row--align-center">
                  <i class="fa fa-user fc-light fs-18" />
                  <label class="fc-dark fs-16 fw-med">
                    {{ apiResults.accountManager.first_name }} {{ apiResults.accountManager.last_name }}
                  </label>
                </div>
                <div class="row row--align-center mt-16">
                  <i class="fa fa-mail fc-light fs-14" />
                  <a
                    class="fc-blue fs-16 fw-med"
                    :href="`mailto:${apiResults.accountManager.user.email}`"
                  >
                    {{ apiResults.accountManager.user.email }}
                  </a>
                </div>
                <div class="row row--align-center mt-16">
                  <i class="fa fa-phone fc-light fs-18" />
                  <a
                    class="fc-blue fs-16 fw-med"
                    :href="`tel:4102042084${apiResults.accountManager.extension
                      ? ` ext ${apiResults.accountManager.extension}`
                      : ''
                    }`"
                  >
                    (410) 204-2084 {{ apiResults.accountManager.extension
                      ? `ext ${apiResults.accountManager.extension}`
                      : ''
                    }}
                  </a>
                </div>
              </template>
            </div>
          </div>

          <div
            v-else
            class="column"
            :key="'employee-view'"
          >
            <div class="MobileHome__card MobileHome__card--noclickable">
              <p class="fs-16">
                Welcome to Bobtail! Unfortunately at this time, the mobile version of the site is
                only accessible via clients.
              </p>
              <p class="fs-16 mt-10">
                However, if you are in a pinch, you can go into your mobile browser settings and check
                the "Request Desktop Site" option to load the desktop version of the site.
              </p>
            </div>
          </div>
        </transition-group>
      </div>
    </transition>
  </div>
</template>

<script>
// Helpers
import {
  Balance,
  ClientBankAccount,
  ClientPayment,
  Employee,
  Invoice,
} from '../utils/api'
import { sanitizeAlgoliaNames } from '../utils/helpers'
import FlagDisplayNames from '../shared/FlagDisplayNames.json'
// Components
import BaseBank from '../components/base-bank.vue'
import BaseButton from '../components/base-button.vue'
import BaseCreditCheckItem from '../components/base-credit-check-item.vue'
import BaseDebtorSearch from '../components/base-debtor-search.vue'

export default {
  name: 'MobileHome',

  components: {
    BaseBank,
    BaseButton,
    BaseCreditCheckItem,
    BaseDebtorSearch,
  },

  async created () {
    this.user = JSON.parse(localStorage.getItem('user'))

    if (!this.user.client) {
      this.createdFinished = true
      return
    }

    await this.getClientAccountManager()
    await this.getTransfers()
    await this.getPendingInvoices()
    await this.getApprovedInvoices()
    await this.getShortpaidInvoices()
    await this.getBalances()
    await this.getBanks()

    this.createdFinished = true
  },

  data () {
    return {
      apiLimit: 3,
      apiResults: {
        accountManager: null,
        approved: [],
        balances: [],
        banks: [],
        clientBalance: 0,
        pending: [],
        shortpaid: [],
        transfers: [],
      },
      createdFinished: false,
      debtor: null,
      flagDisplayName: FlagDisplayNames,
      user: null,
    }
  },

  computed: {
    clientWithoutBankModal () {
      return this.$store.getters.clientWithoutBankModal
    },

    clientWithActiveBankReqVerification () {
      // Banks haven't been retrieved yet
      if (!this.apiResults.banks.length) return

      const activeBank = this.apiResults.banks.filter(bank => bank.status === 'active')[0]

      return ['pending_manual_verification', 'ITEM_LOGIN_REQUIRED']
        .includes(activeBank.plaid_verification_status)
    },
  },

  methods: {
    debtorPaymentText (reason) {
      if (reason === 'shortpay') return 'Shortpaid'
      if (reason === 'overpay') return 'Overpaid'
      if (reason === 'nonpayment') return 'Non-payment'
      return reason
    },

    async getClientAccountManager () {
      try {
        const client = JSON.parse(localStorage.getItem('user')).client
        this.$set(
          this.apiResults,
          'accountManager',
          (await Employee.get(client.account_manager_id)).data
        )
      } catch (error) {
        this.captureSentryEvent(
          'Mobile Home Account Manager Fetch Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError('Error loading account manager:', error)
      }
    },

    async getApprovedInvoices () {
      try {
        this.$set(this.apiResults, 'approved', (await Invoice.queryList({
          client_id: this.user.client.id,
          offset: 0,
          sort_by: 'approved_date',
          sort_direction: 'DESC',
          status: 'approved'
        }, this.apiLimit)).data.rows)

        console.log('API RESULTS:', this.apiResults)
      }
      catch (error) {
        this.captureSentryEvent(
          'Mobile Home "Get Pending Invoices" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s current invoices' })
      }
    },

    async getBalances () {
      try {
        this.$set(this.apiResults, 'balances', (await Balance.getClientBalances({
          client_id: this.user.client.id,
          include: 'clientpayments',
          limit: this.apiLimit,
          offset: 0,
          sort_by: 'accounting',
          sort_direction: 'DESC',
        })).data.rows)
        await this.getClientBalance()
      }
      catch (error) {
        this.captureSentryEvent(
          'Mobile Home "Get Balances" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s balances' })
      }
    },

    async getBanks () {
      try {
        this.$set(this.apiResults, 'banks', (await ClientBankAccount.queryList({
          client_id: this.user.client.id,
        })).data.rows.filter(bank => bank.status === 'active'))
      }
      catch (error) {
        this.captureSentryEvent(
          'Mobile Home "Get Banks" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s banks' })
      }
    },

    async getClientBalance () {
      try {
        this.$set(this.apiResults, 'clientBalance', (
          await Balance.getClientTotalBalance(this.user.client.id)
        ).data.total)
      }
      catch (error) {
        this.captureSentryEvent(
          'Mobile Home "Get Client Balance" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s balance' })
      }
    },

    async getPendingInvoices () {
      try {
        this.$set(this.apiResults, 'pending', (await Invoice.queryList({
          client_id: this.user.client.id,
          is_buyout: false,
          offset: 0,
          sort_by: 'created_at',
          sort_direction: 'DESC',
          status: 'pending',
        }, this.apiLimit)).data.rows)
      }
      catch (error) {
        this.captureSentryEvent(
          'Mobile Home "Get Pending Invoices" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s current invoices' })
      }
    },

    async getShortpaidInvoices () {
      try {
        this.$set(this.apiResults, 'shortpaid', (await Invoice.queryList({
          client_id: this.user.client.id,
          include: 'all',
          limit: this.apiLimit,
          offset: 0,
          or___status_debtor_payment: ['shortpay', 'nonpayment'],
          sort_by: 'paid_date',
          sort_direction: 'DESC',
          status: 'paid'
        })).data.rows)
      }
      catch (error) {
        this.captureSentryEvent(
          'Mobile Home "Get Shortpaid Invoices" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s current invoices' })
      }
    },

    async getTransfers () {
      try {
        const transfers = (await ClientPayment.queryList({
          client_id: this.user.client.id,
          include: ['bankpayments', 'balance_invoices'],
          limit: this.apiLimit,
          offset: 0,
          sort_by: 'created_at',
          sort_direction: 'DESC',
        })).data
        this.$set(this.apiResults, 'transfers', transfers.rows.map(transfer => {
          // sum the approved_invoice_adjustment_amount
          transfer.approved_invoice_adjustment_amount = transfer.invoices
            .reduce((acc, curr) => (acc + curr.approved_invoice_adjustment_amount), 0)
          return transfer
        }))
      }
      catch (error) {
        this.captureSentryEvent(
          'Mobile Home "Get Transfers" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s transfers' })
      }
    },

    formatFlagMessage (lastFlagMessage) {
      if (!lastFlagMessage) return null
      return lastFlagMessage.split(', ').length > 1
        ? `${lastFlagMessage.split(', ').length} Flags`
        : this.flagDisplayName[lastFlagMessage]
          || lastFlagMessage
    },

    goToApprovedInvoices () {
      this.$router.push({ name: 'approved' })
    },

    goToBalanceHistory () {
      this.$router.push({
        name: 'dashboard-client-balance-history',
        params: {
          id: this.user.client.id,
        },
      })
    },

    goToBanking () {
      this.$router.push({
        name: 'dashboard-client-banking',
        params: {
          id: this.user.client.id,
        },
      })
    },

    goToPendingInvoices () {
      this.$router.push({ name: 'pending' })
    },

    goToShortpaidInvoices () {
      // Go to paid invoices page toggled for shortpaid invoices
      this.$router.push({
        name: 'paid',
        query: {
          status_debtor_payment: 'shortpay',
        },
      })
    },

    goToSubmitInvoice () {
      // TODO: remove whenever I figure out NavigationDuplicated
      // eslint-disable-next-line no-unused-vars
      this.$router.push({ name: 'submit-invoice' }).catch(err => {})
    },

    goToTransfers () {
      this.$router.push({
        name: 'dashboard-client-transfers',
        params: {
          id: this.user.client.id,
        },
      })
    },

    sanitizeAlgoliaNames,

    selectDebtor (debtor) {
      if (debtor) this.debtor = debtor
    }
  },
}
</script>

<style lang="sass">
.MobileHome
  margin-top: 0 !important // override .mobile-browsing
  min-height: 100vh
  padding: 1rem
  position: relative

  .BaseBank
    border: none
    border-radius: 0
    box-shadow: none
    height: unset // Only 1 bank is every displayed
    padding: 0

  .Divider
    margin-bottom: rem(14px)
    margin-top: rem(14px)

  &__balance-flag
    border-radius: rem(15px)
    display: inline-block
    line-height: 1
    padding: rem(6px) rem(12px)

    &--bg-green
      background-color: rgba(#34AA44, 0.2)
      color: $success

    &--bg-red
      background-color: #FFEDE9
      color: $danger

    &--bg-light
      background-color: lighten(#A6A8AD, 28)
      color: $text-light

  &__card
    +flex($direction: column)
    background-color: white
    border-radius: $border-radius
    cursor: pointer
    margin-top: rem(15px)
    padding: rem(14px) rem(16px)
    width: 100%

    &--noclickable
      cursor: default

  &__card-header
    +flex($direction: row, $justify: space-between, $align: center)
    width: 100%

    &::hover,
    &::focus
      background-color: rgba(0,0,0,0.5)

  &__card-header-lbl
    +font-medium
    color: $branding
    font-size: rem(18px)
    line-height: rem(28px)

  &__debtor-search
    width: 100%

  &__loading-indicator
    +flex($align: center, $justify: center)
    height: 100vh
    left: 0
    position: absolute
    top: 0
    width: 100%

  &__logo
    height: rem(37px)

  &__nacha_sign
    margin-left: rem(-10px)

  &__result
    border-bottom: $border
    // space between result's bottom border and next result
    margin-bottom: rem(17px)
    // space below result
    padding-bottom: rem(17px)

    &:last-child
      border-bottom: 0
      margin-bottom: 0
      padding-bottom: 0
</style>