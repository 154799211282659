var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "BaseCreditCheckItem bg-white row",
        { clickable: _vm.user.employee }
      ],
      on: { click: _vm.goToDebtorCredit }
    },
    [
      _c("base-debtor-rating", {
        staticClass: "mr-24",
        attrs: { rating: _vm.debtor.display_rating, size: "medium" }
      }),
      _c("div", { staticClass: "column mt-1" }, [
        _c("label", { staticClass: "fs-22 mb-4" }, [
          _vm._v(_vm._s(_vm.debtor.name))
        ]),
        _c("label", { staticClass: "fc-light" }, [
          _vm._v(_vm._s(_vm.debtor.city) + ", " + _vm._s(_vm.debtor.state))
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }