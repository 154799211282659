var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "MobileHome bg-blue column" },
    [
      _c("img", {
        staticClass: "MobileHome__logo",
        attrs: {
          alt: "Bobtail's logo",
          src: require("../assets/logo--white.png")
        }
      }),
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__opacity-fade" } },
        [
          !_vm.createdFinished
            ? _c("div", { key: "loading-indicator" }, [
                _c("div", { staticClass: "MobileHome__loading-indicator" }, [
                  _c("i", {
                    staticClass:
                      "fa fa-processing fa-spin fa--none fc-white fs-60"
                  })
                ])
              ])
            : _c(
                "div",
                { key: "submit-btn-user-cards", staticClass: "width-100" },
                [
                  _vm.createdFinished &&
                  (!_vm.clientWithoutBankModal ||
                    !_vm.clientWithActiveBankReqVerification)
                    ? _c(
                        "base-button",
                        {
                          staticClass:
                            "bg-green fc-white fw-med mt-21 width-100",
                          attrs: { id: "Testing__submitinvoice" },
                          on: { click: _vm.goToSubmitInvoice }
                        },
                        [
                          _c("i", { staticClass: "fa fa-add-circle" }),
                          _vm._v("\n        Submit Invoice\n      ")
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "transition-group",
                    {
                      staticClass: "column",
                      attrs: { name: "Transition__fade", tag: "div" }
                    },
                    [
                      _vm.user.client
                        ? _c(
                            "div",
                            { key: "client-view", staticClass: "column" },
                            [
                              _vm.clientWithoutBankModal ||
                              _vm.clientWithActiveBankReqVerification
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "MobileHome__card",
                                      attrs: { id: "Testing__bankaccountinfo" },
                                      on: { click: _vm.goToBanking }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "MobileHome__card-header",
                                          attrs: { tabindex: "0" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row row--align-center row--width-auto"
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "MobileHome__card-header-lbl"
                                                },
                                                [_vm._v("Bank Account")]
                                              ),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "fc-red fs-14 ml-6"
                                                },
                                                [_vm._v("(Required)")]
                                              )
                                            ]
                                          ),
                                          _c("i", {
                                            staticClass: "fa fa-chevron fc-blue"
                                          })
                                        ]
                                      ),
                                      _c("hr", { staticClass: "Divider" }),
                                      _vm.apiResults.banks.length
                                        ? _vm._l(_vm.apiResults.banks, function(
                                            bank
                                          ) {
                                            return _c("base-bank", {
                                              key: bank.id,
                                              attrs: {
                                                "bank-account": bank,
                                                "user-is-client": !!_vm.user
                                                  .client
                                              }
                                            })
                                          })
                                        : [
                                            _c(
                                              "label",
                                              { staticClass: "row mt-14" },
                                              [_vm._v("No banks added")]
                                            )
                                          ]
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "MobileHome__card",
                                  on: { click: _vm.goToTransfers }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "MobileHome__card-header",
                                      attrs: { tabindex: "0" }
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "MobileHome__card-header-lbl"
                                        },
                                        [_vm._v("Transfers")]
                                      ),
                                      _c("i", {
                                        staticClass: "fa fa-chevron fc-blue"
                                      })
                                    ]
                                  ),
                                  _c("hr", { staticClass: "Divider" }),
                                  _vm.apiResults.transfers.length
                                    ? _vm._l(_vm.apiResults.transfers, function(
                                        result,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: "transfers-" + index,
                                            staticClass:
                                              "MobileHome__result column"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row row--align-center row--justify-between"
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "fs-16 fw-med"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("datetime")(
                                                          result.created_at,
                                                          "MM/DD/YYYY"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "fs-16 fw-med"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          Intl.NumberFormat(
                                                            "en-US",
                                                            {
                                                              style: "currency",
                                                              currency: "USD"
                                                            }
                                                          ).format(
                                                            result.amount / 100
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    : [
                                        _c("label", { staticClass: "row" }, [
                                          _vm._v("No transfers currently")
                                        ])
                                      ]
                                ],
                                2
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "MobileHome__card",
                                  attrs: { id: "Testing__pendinginvoices" },
                                  on: { click: _vm.goToPendingInvoices }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "MobileHome__card-header",
                                      attrs: { tabindex: "0" }
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "MobileHome__card-header-lbl"
                                        },
                                        [_vm._v("Pending Invoices")]
                                      ),
                                      _c("i", {
                                        staticClass: "fa fa-chevron fc-blue"
                                      })
                                    ]
                                  ),
                                  _c("hr", { staticClass: "Divider" }),
                                  _vm.apiResults.pending.length
                                    ? _vm._l(_vm.apiResults.pending, function(
                                        result,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: "pending-" + index,
                                            staticClass:
                                              "MobileHome__result column"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row row--align-center row--justify-between"
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "fs-16 fw-med"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.sanitizeAlgoliaNames(
                                                            result.debtor_selected_name
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "fs-16 fw-med"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          Intl.NumberFormat(
                                                            "en-US",
                                                            {
                                                              style: "currency",
                                                              currency: "USD"
                                                            }
                                                          ).format(
                                                            result.total_amount /
                                                              100
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row row--justify-between"
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "fc-mid fs-14"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("datetime")(
                                                          result.created_at,
                                                          "MM/DD/YYYY"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "fc-red fs-14 uppercase"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatFlagMessage(
                                                          result.last_flag_message
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    : [
                                        _c(
                                          "label",
                                          { staticClass: "row mt-14" },
                                          [_vm._v("No pending invoices")]
                                        )
                                      ]
                                ],
                                2
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "MobileHome__card",
                                  attrs: { id: "Testing__approvedinvoices" },
                                  on: { click: _vm.goToApprovedInvoices }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "MobileHome__card-header",
                                      attrs: { tabindex: "0" }
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "MobileHome__card-header-lbl"
                                        },
                                        [_vm._v("Approved Invoices")]
                                      ),
                                      _c("i", {
                                        staticClass: "fa fa-chevron fc-blue"
                                      })
                                    ]
                                  ),
                                  _c("hr", { staticClass: "Divider" }),
                                  _vm.apiResults.approved.length
                                    ? _vm._l(_vm.apiResults.approved, function(
                                        result,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: "approved-" + index,
                                            staticClass:
                                              "MobileHome__result column"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row row--align-center row--justify-between"
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "fs-16 fw-med"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.sanitizeAlgoliaNames(
                                                            result.debtor_selected_name
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "fs-16 fw-med"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          Intl.NumberFormat(
                                                            "en-US",
                                                            {
                                                              style: "currency",
                                                              currency: "USD"
                                                            }
                                                          ).format(
                                                            result.total_amount /
                                                              100
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row row--justify-between"
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "fc-mid fs-14"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("datetime")(
                                                          result.created_at,
                                                          "MM/DD/YYYY"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "fc-red fs-14 uppercase"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatFlagMessage(
                                                          result.last_flag_message
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    : [
                                        _c("label", { staticClass: "row" }, [
                                          _vm._v("No approved invoices")
                                        ])
                                      ]
                                ],
                                2
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "MobileHome__card",
                                  attrs: { id: "Testing__shortpaidinvoices" },
                                  on: { click: _vm.goToShortpaidInvoices }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "MobileHome__card-header",
                                      attrs: { tabindex: "0" }
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "MobileHome__card-header-lbl"
                                        },
                                        [_vm._v("Shortpaid Invoices")]
                                      ),
                                      _c("i", {
                                        staticClass: "fa fa-chevron fc-blue"
                                      })
                                    ]
                                  ),
                                  _c("hr", { staticClass: "Divider" }),
                                  _vm.apiResults.shortpaid.length
                                    ? _vm._l(_vm.apiResults.shortpaid, function(
                                        result,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: "shortpaid-" + index,
                                            staticClass:
                                              "MobileHome__result column"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row row--align-center row--justify-between"
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "fs-16 fw-med"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.sanitizeAlgoliaNames(
                                                            result.debtor_selected_name
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "fs-16 fw-med"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          Intl.NumberFormat(
                                                            "en-US",
                                                            {
                                                              style: "currency",
                                                              currency: "USD"
                                                            }
                                                          ).format(
                                                            result.total_amount /
                                                              100
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row row--justify-between"
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass: "fc-mid fs-14"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("datetime")(
                                                          result.created_at,
                                                          "MM/DD/YYYY"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "fc-red fs-14 uppercase"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatFlagMessage(
                                                          result.last_flag_message
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    : [
                                        _c(
                                          "label",
                                          { staticClass: "row mt-14" },
                                          [_vm._v("No shortpaid invoices")]
                                        )
                                      ]
                                ],
                                2
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "MobileHome__card",
                                  attrs: { id: "Testing__balancehistory" },
                                  on: { click: _vm.goToBalanceHistory }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "MobileHome__card-header",
                                      attrs: { tabindex: "0" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row row--align-center row--width-auto"
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "MobileHome__card-header-lbl"
                                            },
                                            [_vm._v("Balance History")]
                                          ),
                                          _c(
                                            "label",
                                            {
                                              class: [
                                                "MobileHome__balance-flag fs-16 ml-8",
                                                {
                                                  "MobileHome__balance-flag--bg-light":
                                                    _vm.apiResults
                                                      .clientBalance === 0,
                                                  "MobileHome__balance-flag--bg-green":
                                                    _vm.apiResults
                                                      .clientBalance > 0,
                                                  "MobileHome__balance-flag--bg-red":
                                                    _vm.apiResults
                                                      .clientBalance < 0
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    Intl.NumberFormat("en-US", {
                                                      style: "currency",
                                                      currency: "USD"
                                                    }).format(
                                                      _vm.apiResults
                                                        .clientBalance / 100
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c("i", {
                                        staticClass: "fa fa-chevron fc-blue"
                                      })
                                    ]
                                  ),
                                  _c("hr", { staticClass: "Divider" }),
                                  _vm.apiResults.balances.length
                                    ? _vm._l(_vm.apiResults.balances, function(
                                        result,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: "balances-" + index,
                                            staticClass:
                                              "MobileHome__result column"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row row--align-center row--justify-between"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "column column--width-auto"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "fs-16 fw-med capitalize"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.debtorPaymentText(
                                                              result.reason
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "fc-mid fs-14"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("datetime")(
                                                              result.created_at,
                                                              "MM/DD/YYYY"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "label",
                                                  {
                                                    class: [
                                                      "fs-16 fw-med",
                                                      {
                                                        "fc-red":
                                                          result.nacha_sign ===
                                                          "-"
                                                      }
                                                    ]
                                                  },
                                                  [
                                                    result.nacha_sign === "-"
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "MobileHome__nacha_sign fc-red"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      -\n                    "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          Intl.NumberFormat(
                                                            "en-US",
                                                            {
                                                              style: "currency",
                                                              currency: "USD"
                                                            }
                                                          ).format(
                                                            result.amount / 100
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    : [
                                        _c(
                                          "label",
                                          { staticClass: "row mt-14" },
                                          [_vm._v("No balances")]
                                        )
                                      ]
                                ],
                                2
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "MobileHome__card MobileHome__card--noclickable"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "MobileHome__card-header" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "MobileHome__card-header-lbl"
                                        },
                                        [_vm._v("Credit Check")]
                                      )
                                    ]
                                  ),
                                  _c("hr", { staticClass: "Divider" }),
                                  _c("base-debtor-search", {
                                    staticClass:
                                      "MobileHome__debtor-search width-100",
                                    attrs: {
                                      label: true,
                                      "label-for": "debtor",
                                      placeholder: "Company name or MC ID",
                                      statuses: ["active", "sandbox"]
                                    },
                                    on: { selected: _vm.selectDebtor }
                                  }),
                                  _c(
                                    "transition",
                                    { attrs: { name: "Transition__fade" } },
                                    [
                                      _vm.debtor
                                        ? _c("base-credit-check-item", {
                                            staticClass: "mt-14",
                                            attrs: { debtor: _vm.debtor }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              !_vm.clientWithoutBankModal &&
                              !_vm.clientWithActiveBankReqVerification
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "MobileHome__card",
                                      attrs: { id: "Testing__bankaccountinfo" },
                                      on: { click: _vm.goToBanking }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "MobileHome__card-header",
                                          attrs: { tabindex: "0" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row row--align-center row--width-auto"
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "MobileHome__card-header-lbl"
                                                },
                                                [_vm._v("Bank Account")]
                                              ),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "fc-red fs-14 ml-6"
                                                },
                                                [_vm._v("(Required)")]
                                              )
                                            ]
                                          ),
                                          _c("i", {
                                            staticClass: "fa fa-chevron fc-blue"
                                          })
                                        ]
                                      ),
                                      _c("hr", { staticClass: "Divider" }),
                                      _vm.apiResults.banks.length
                                        ? _vm._l(_vm.apiResults.banks, function(
                                            bank
                                          ) {
                                            return _c("base-bank", {
                                              key: bank.id,
                                              attrs: {
                                                "bank-account": bank,
                                                "user-is-client": !!_vm.user
                                                  .client
                                              }
                                            })
                                          })
                                        : [
                                            _c(
                                              "label",
                                              { staticClass: "row mt-14" },
                                              [_vm._v("No banks added")]
                                            )
                                          ]
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "MobileHome__card MobileHome__card--noclickable"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "MobileHome__card-header" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "fc-dark fs-18 fw-med" },
                                        [_vm._v("Your Account Rep")]
                                      )
                                    ]
                                  ),
                                  _c("hr", { staticClass: "Divider" }),
                                  _vm.apiResults.accountManager
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "row row--align-center"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-user fc-light fs-18"
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "fc-dark fs-16 fw-med"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.apiResults
                                                        .accountManager
                                                        .first_name
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.apiResults
                                                        .accountManager
                                                        .last_name
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row row--align-center mt-16"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-mail fc-light fs-14"
                                            }),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "fc-blue fs-16 fw-med",
                                                attrs: {
                                                  href:
                                                    "mailto:" +
                                                    _vm.apiResults
                                                      .accountManager.user.email
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.apiResults
                                                        .accountManager.user
                                                        .email
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row row--align-center mt-16"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-phone fc-light fs-18"
                                            }),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "fc-blue fs-16 fw-med",
                                                attrs: {
                                                  href:
                                                    "tel:4102042084" +
                                                    (_vm.apiResults
                                                      .accountManager.extension
                                                      ? " ext " +
                                                        _vm.apiResults
                                                          .accountManager
                                                          .extension
                                                      : "")
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  (410) 204-2084 " +
                                                    _vm._s(
                                                      _vm.apiResults
                                                        .accountManager
                                                        .extension
                                                        ? "ext " +
                                                            _vm.apiResults
                                                              .accountManager
                                                              .extension
                                                        : ""
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          )
                        : _c(
                            "div",
                            { key: "employee-view", staticClass: "column" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "MobileHome__card MobileHome__card--noclickable"
                                },
                                [
                                  _c("p", { staticClass: "fs-16" }, [
                                    _vm._v(
                                      "\n              Welcome to Bobtail! Unfortunately at this time, the mobile version of the site is\n              only accessible via clients.\n            "
                                    )
                                  ]),
                                  _c("p", { staticClass: "fs-16 mt-10" }, [
                                    _vm._v(
                                      '\n              However, if you are in a pinch, you can go into your mobile browser settings and check\n              the "Request Desktop Site" option to load the desktop version of the site.\n            '
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                    ]
                  )
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }