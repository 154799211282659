<template>
  <div
    @click="goToDebtorCredit"
    :class="['BaseCreditCheckItem bg-white row', {'clickable': user.employee}]"
  >
    <base-debtor-rating
      class="mr-24"
      :rating="debtor.display_rating"
      :size="'medium'"
    />
    <div class="column mt-1">
      <label class="fs-22 mb-4">{{ debtor.name }}</label>
      <label class="fc-light">{{ debtor.city }}, {{ debtor.state }}</label>
    </div>
  </div>
</template>
<script>

// Components
import BaseDebtorRating from './base-debtor-rating.vue';

export default {
  name: 'BaseCreditCheckItem',

  components: {
    BaseDebtorRating,
  },

  props: {
    debtor: {
      type: Object,
      required: true,
    },
  },

  created () {
    this.user = JSON.parse(localStorage.getItem('user'))
  },

  data () {
    return {
      user: null
    }
  },

  methods: {
    goToDebtorCredit () {
      // only allow routing if the user is an employee
      if (this.user.employee) {
        this.$router.push({
          name: 'debtor-credit',
          params: { id: this.debtor.id.toString() }
        })
      }
    }
  }
};
</script>

<style lang="sass">
.BaseCreditCheckItem
  $block: &
  border: $border
  border-radius: $border-radius
  box-shadow: $shadow-b
  margin-bottom: rem(20px)
  padding: rem(30px)
  width: 73%

  &:last-child
    margin-bottom: 0

  .BaseDebtorRating--medium
    height: rem(62px)
    width: rem(62px)

    .BaseDebtorRating__label
      font-size: rem(24px)

  @media #{$tablet-landscape-and-down}
    padding: 1rem
    width: 100%
</style>